import { init, browserTracingIntegration } from "@sentry/remix";
import { useLocation, useMatches } from "@remix-run/react";
import { useEffect } from "react";
import { getConfig } from "@portal-frontend-ssr/config";

/**
 * Initialize Sentry for the browser using the dsn configured in the environment.
 */
export function initSentry() {
  const { ENVIRONMENT, SENTRY_DSN } = getConfig();

  if (ENVIRONMENT === "local") return console.info("Sentry is disabled in local environment ✅");
  if (!SENTRY_DSN) return console.error("Action required! SENTRY_DSN is not set. Sentry will not be initialized. ❌");

  const browserDomain = location.hostname.split(".").slice(1).join(".");

  // Sentry initialization
  init({
    enabled: true,
    dsn: SENTRY_DSN,
    tracesSampleRate: ENVIRONMENT === "production" ? 0.1 : 1.0,
    environment: ENVIRONMENT,

    // This only allows errors which occurs from either
    // - the current domain
    // - https://*.apicdn.sanity.io
    // - https://*.cookiebot.com
    allowUrls: [
      new RegExp(`https?://(.*\\.)?${browserDomain.replaceAll(".", "\\.")}`),
      /https?:\/\/(.*\.)?apicdn.sanity\.io/,
      /https?:\/\/(.*\.)?cookiebot\.com/,
    ],

    integrations: [
      browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ],
    beforeSend(event, hint) {
      if (hint.originalException instanceof Error) {
        // ignore "TypeError: Failed to fetch" error from live/ad-manager-bundle
        const error = hint.originalException;
        if (
          !!error.name.match(/TypeError/i)?.length &&
          !!error.message.match(/Failed to fetch/i)?.length &&
          !!error.stack?.match(/ad-manager/i)?.length
        ) {
          return null;
        }
      }

      return event;
    },
  });
}
